<template>
  <ul>
    <li
      :key="1"
      :class="['product-type', activeEl === 'all' ? 'active' : '']"
      @click="updateAllProducts"
    >
      All
    </li>
    <li
      v-for="type in this.$store.state.products"
      :key="type.slug"
      @click="updateItems(type.slug)"
      :class="['product-type', activeEl === type.slug ? 'active' : '']"
    >
      {{ type.type }}
    </li>
  </ul>

  <section class="product-area" v-show="this.products.length">
    <SingleProduct v-for="item in products" :key="item.name" :product="item" />
  </section>
</template>

<script>
import SingleProduct from "./SingleProduct";

export default {
  name: "ProductList",
  data() {
    return {
      products: [],
      activeEl: "all",
    };
  },
  components: { SingleProduct },
  methods: {
    updateItems(id) {
      const arr = this.$store.state.products.filter((product) => {
        return product.slug === id;
      })[0];
      if (arr === undefined) {
        this.$router.replace({ name: "Products", params: { type: "" } });
        return;
      }
      this.products = arr.products;
      this.activeEl = arr.slug;

      this.$router.replace({ name: "Products", params: { type: id } });
      //this.$route.params({})
    },

    updateAllProducts() {
      let self = this;
      this.products = [];
      this.activeEl = "all";
      this.$store.state.products.forEach((value) => {
        value.products.forEach((item) => {
          self.products.push(item);
        });
      });
      this.$router.replace({ name: "Products", params: { type: "" } });
    },

    checkRouteParam() {
      const params = this.$route.params;
      const path = this.$route.name;
      if (path === "Products") {
        if (params.type === "" || params.type === "detail")
          this.updateAllProducts();
        else this.updateItems(params.type);
      } else {
        // this.$router.replace({name: path})
      }
    },
  },
  computed: {},
  watch: {
    $route() {
      this.checkRouteParam();
    },
  },
  created() {
    this.checkRouteParam();
  },
};
</script>

<style scoped lang="scss">
ul {
  list-style: none;
  padding-left: 0;

  .product-type {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #aaa;
    font-weight: 400;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    cursor: pointer;
    transition: all ease-in-out 200ms;

    &.active,
    &:hover {
      background: $theme-color;
      border: 1px solid $theme-color;
      color: white;
    }
  }
}
</style>
