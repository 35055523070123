<template>
  <!--   Header-->
  <section class="header">
    <div class="content container">
      <h1>Products Overview</h1>
      <p>A piping system is as strong as its weakest gasket.</p>
      <p>
        Often overlooked but quite the underdog, a strong gasket made with the
        right properties can provide the perfect sealing to a pipe network. On
        the contrary, a bad gasket can prove to be a water pipeline’s Achilles’
        Heel.
      </p>
      <p>Leakages anywhere aren’t good, are they?</p>
      <div class="mt-3 hashtags">
        <label class="tags">#waterindustry</label>
        <label class="tags">#pipesandfitting</label>
        <label class="tags">#health</label>
        <label class="tags">#pipelines</label>
        <label class="tags">#pipelineconstruction</label>
        <label class="tags">#waterpipes</label>
        <label class="tags">#gaskets</label>
        <label class="tags">#rubberindustry</label>
        <label class="tags">#rubberproducts</label>
        <label class="tags">#pipes</label>
      </div>
    </div>
  </section>

  <section class="products">
    <div class="content container">
      <h1>Our Latest Products</h1>
      <p>
        All Kinds of Industrial Rubber Products including Tyton Gaskets, Seals,
        Elastometric Pads & Moulded Rubber Items
      </p>
      <br /><br />
      <ProductList />
    </div>
  </section>

  <section id="chemicals">
    <div class="container content">
      <p class="heading-highlight">Choose Your Polymer Wisely</p>
      <h1>
        We Specialize in <strong class="color-theme">Polymer-Use Grid</strong>
      </h1>
      <div class="underline_highlight d-inline-block"></div>

      <div class="row mt-5">
        <div class="col-md-3">
          <div class="card">
            <font-awesome-icon
              :icon="['fa', 'atom']"
              size="2x"
              class="color-theme"
            />
            <h2 class="mt-4">EPDM</h2>
            <label>Ethylene Propylene Diene Monomer (M-class) Rubber</label>
            <p class="mt-4">
              Water & steam resistance, ozone resistance, weathering & ageing
              resistance strong resistance to acids, alkalis and oxygenated
              solvents suitable for external use high temperature flexibility.
            </p>
          </div>
        </div>

        <div class="col-md-3 mt-5">
          <div class="card">
            <font-awesome-icon
              :icon="['fa', 'atom']"
              size="2x"
              class="color-theme"
            />
            <h2 class="mt-4">SBR</h2>
            <label>Styrene-Butadiene Rubber</label>
            <p class="mt-4">
              Abrasion resistance, weathering and ageing resistance, resistant
              to emulsification in damp conditions, water and steam resistance,
              suitable for external use, high temperature flexibility.
            </p>
          </div>
        </div>

        <div class="col-md-3">
          <div class="card">
            <font-awesome-icon
              :icon="['fa', 'atom']"
              size="2x"
              class="color-theme"
            />
            <h2 class="mt-4">NEOPRENE</h2>
            <p class="mt-2">
              Moderate to high oil resistance, excellent abrasion and
              weathering, strong flame resistance, strong resistance against
              acids, alkalis, flex cracking & abrasion, suitable for internal
              use.
            </p>
          </div>
        </div>

        <div class="col-md-3 mt-5">
          <div class="card">
            <font-awesome-icon
              :icon="['fa', 'atom']"
              size="2x"
              class="color-theme"
            />
            <h2 class="mt-4">NITRILE</h2>
            <p class="mt-2">
              Excellent oil and gasoline resistance, strong permeability, higher
              heat and abrasion resistance than Neoprene, strong resistance to
              alkalis and acids depending upon nitrile content in product.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductList from "../components/ProductList";

export default {
  name: "Products",
  components: { ProductList },
};
</script>

<style scoped lang="scss">
p {
  color: $light-text-color;
}

.header {
  height: 90vh;
  background-color: red;
  margin-top: -6.5rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)),
    url("../assets/images/header/5.jpg");
  background-size: cover;
  display: flex;
  align-items: center;

  .content {
    color: white;
    text-align: left;
    margin-top: 4rem;

    h1 {
      font-size: 3rem;
      width: 70%;
      border-left: 8px solid $theme-color;
      padding-left: 1rem;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    p {
      width: 50%;
      margin-top: 1.2rem;
      color: white;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .hashtags {
      width: 70%;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 4rem 0;
    margin-top: 0;
  }
}

.products {
  background: $section-background;
  padding: 4rem 0;

  .content {
    p {
      font-weight: 300;
      font-size: 0.9rem;
      letter-spacing: 0.02rem;
    }
  }
}

#chemicals {
  background: $section-background;
  padding: 6rem 0;
  text-align: center;
  background: url("../assets/images/products/background.jpg");
  background-size: cover;
  background-position: center;

  .heading-highlight {
    font-weight: 300;
    font-size: 1.5rem;
  }

  .card {
    text-align: left;
    padding: 2rem 1.5rem;
    transition: all ease 500ms;
    cursor: pointer;

    p {
      font-weight: 300;
    }

    &:hover {
      margin-top: -1rem;

      h1 {
        color: $theme-color;
      }
    }
  }
}
</style>
